import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../utils/css/screen.css"

const IndustryCard = ({ data, name, imageName }) => {
  console.log(data)
  console.log(name, imageName)
  return (
    <div className="industry-card">
      <Img
        fluid={data[imageName].childImageSharp.fluid}
        className="industry-img"
      />
      <h3 className="industry-text">{name}</h3>
    </div>
  )
}
const AboutPage = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const industries = [
    "Electrical and Electronic Supplies",
    "Fertilizers",
    "Safety and PPE Supplies",
    "Agri Products, Parts and Supplies",
    "Pharmaceuticals",
    "Food, Beverage and Catering",
    "Fashion and Jewellery",
    "Medical Equipment and Supplies",
    "Plastics and Packaging",
    "Building,Paints and Construction Supplies",
    "Industrial and Manufacturing Equipment",
    "Hobby Supplies, Toys and Games",
  ]
  const imageNames = [
    "ees",
    "fert",
    "ss",
    "agri",
    "pharma",
    "food",
    "fashion",
    "medical",
    "plastics",
    "building",
    "industrial",
    "arts",
  ]
  return (
    <Layout title={siteTitle} currentLink="/industries">
      <SEO
        title="Trunkroad works for all B2B and wholesale companies across industries"
        description="Trunkroad is a modular platform and has customizations and flexibility to every B2B and wholesale company in any industry and sector."
        keywords={[
          `B2B`,
          `B2B Commerce`,
          `AI Sales`,
          `Sales Intelligence`,
          "B2B eCommerce",
          "Sales Artificial Intelligence",
          "B2B AI",
          "B2B Sales AI",
        ]}
      />

      <div className="banner">
        <Img
          fluid={data.imageFile.childImageSharp.fluid}
          className="banner-img"
        />
        <div className="banner-body">
          <h1 className="centered caption">Every business is unique.</h1>
          <h4>
            Trunkroad is a modular platform and has customizations and
            flexibility to every B2B and wholesale company in any industry and
            sector.
          </h4>
        </div>
      </div>
      <div className="industries text-center">
        {industries.map((name, index) => (
          <IndustryCard data={data} name={name} imageName={imageNames[index]} />
        ))}
      </div>
      <div className="article text-center">
        <div className="section sec1">
          <div className="section-txt">
            <h3>Don't see your industry here?</h3>
            <h5 className="section-text">
              <h4>
                We work with a variety of clients. Please get in touch - we
                would love to help you.
              </h4>
            </h5>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    imageFile: file(relativePath: { eq: "HeroInd.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ees: file(relativePath: { eq: "industries/ees.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fert: file(relativePath: { eq: "industries/fert.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ss: file(relativePath: { eq: "industries/ss.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    agri: file(relativePath: { eq: "industries/agri.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pharma: file(relativePath: { eq: "industries/pharma.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    food: file(relativePath: { eq: "industries/food.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fashion: file(relativePath: { eq: "industries/fashion.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    medical: file(relativePath: { eq: "industries/medical.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    plastics: file(relativePath: { eq: "industries/plastics.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    building: file(relativePath: { eq: "industries/building.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    industrial: file(relativePath: { eq: "industries/industrial.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    arts: file(relativePath: { eq: "industries/arts.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
